<template>
    <div>
        <div class="flex items-center gap-3 w-full relative">
            <font-awesome-icon v-if="!element?.ristrictDrag" icon="grip-vertical"
                class="text-base-content-300 text-gray-500" />
                <div v-if="element?.ristrictDrag" class="ml-2"></div>
            <!--<font-awesome-icon icon="grip-vertical" class="text-gray-500" />-->
            <div class="cascadeOpen w-3 absolute" @click.stop="ISEDITPERMISSIONS && fChange(element, section)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="40" viewBox="0 0 8 5" fill="none" :class="{'arrowUpward':cascadeOpen}">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.58021 0.703117C0.705077 0.578387 0.874411 0.508318 1.05097 0.508318C1.22754 0.508318 1.39687 0.578387 1.52174 0.703117L3.71442 2.89404L5.90711 0.703118C5.96853 0.639572 6.04201 0.588886 6.12325 0.554017C6.20448 0.519148 6.29186 0.500794 6.38027 0.500026C6.46869 0.499259 6.55637 0.516092 6.6382 0.549546C6.72003 0.582999 6.79437 0.632402 6.85689 0.694871C6.91941 0.75734 6.96886 0.831626 7.00234 0.913392C7.03582 0.995158 7.05266 1.08277 7.0519 1.17111C7.05113 1.25945 7.03276 1.34676 6.99786 1.42793C6.96296 1.5091 6.91224 1.58252 6.84864 1.64389L4.18519 4.3052C4.06032 4.42993 3.89099 4.5 3.71442 4.5C3.53786 4.5 3.36853 4.42993 3.24366 4.3052L0.580209 1.64389C0.455379 1.51912 0.385254 1.34992 0.385254 1.1735C0.385254 0.997082 0.45538 0.827884 0.58021 0.703117Z" fill="white"/>
                        </svg>
            </div>
            <div :class="[showSidePanel && activeFieldPanel.field.id === element.id ?
                'border-solid  bg-blue-200 border-primary border-r-4 border-0' :
                'hover:bg-blue-200', fieldClass]"
                class="check-field bg-gray-100 transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow cst-border-left">
                <div class="flex gap-3 items-center text-sm FieldInfo">
                    <input v-if="edited" v-model="element.label" @click.stop="$emit('fieldNameClick', element)" 
                        @blur="onUpdateFieldLabel(section, element)"
                        :class="!element.label ? 'border border-dashed border-red-300 ' : ''" type="text"
                        class="section_name w-48 bg-transparent border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                    <span v-else v-tippy :content="fieldLabel"
                        class="section_name truncate w-48 mx-4 bg-transparent border-0 outline-none p-1 text-md text-base-content font-semibold truncate">{{
                            fieldLabel }}</span>
                    <div v-if="this.$route.params.action !== 'view' && !['Candidate Consent', 'Candidate Optional Consent'].includes(element.type) && ISEDITPERMISSIONS"
                        class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                        :class="edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'" @click.stop="onEdit()">
                        <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current"
                            v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
                    </div>
                    <span v-tippy :content="element.name" class="truncate">{{ getElementName }}</span>
                    <GapInfo v-if="element.use_in_gap_validation" :element="element"
                        @click.native.stop="ISEDITPERMISSIONS && $emit('showGapValidationModal')" />

                    <FieldTag class="bg-gray-200 w-28 cascadeBgColor" @onClick.stop="ISEDITPERMISSIONS && fChange(element, section)"
                        :label="'Cascading Fields'" :title="`Field Source: ${'Cascading Fields'}`" />
                    <!-- <CascadedIcon class="w-5 text-primary fill-current"
                        @click.stop="ISEDITPERMISSIONS && fChange(element, section)"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" /> -->
                </div>
                <!-- <span v-tippy :content="element.name">{{ element.name }}</span>
                <GapInfo v-if="element.use_in_gap_validation" :element="element"
                    @click.native.stop="ISEDITPERMISSIONS && $emit('showGapValidationModal')" />
                <CascadedIcon class="w-5 text-primary fill-current"
                    @click.stop="ISEDITPERMISSIONS && $emit('showFieldSettingsModal')" /> -->
                <div class="gap-3.5 flex items-left ml-auto" :class="{ 'cascadeMargin': inFormAdmin}">
                    <div class="gap-3 flex items-center mr-10 pr-10" :class="{PairedButton:sectionItem}">
                        <FieldTag v-if="element.ui_label || element.field_type" :label="element.ui_label || element.field_type"
                            :title="getTitle(element)" />
                        <FieldTag class="bg-gray-200" :label="element.is_custom ? 'Custom' : 'System Provided'"
                            :title="`Field Source: ${element.is_custom ? 'Custom' : 'System Provided'}`" />
                    </div>
                    <div class="block_visible" v-if="outSideBlockVisibility">
                        <Checkbox @click.native.stop v-model="useblockvisible" @input="changeblockVisibile(element,true)"
                            :disabled="this.$route.params.action == 'view'" />
                    </div>
                    <!-- <button @click.stop="$emit('handleShowPanel', { section, element })" type="button" class="px-4 py-1 font-light text-xs md:text-md text-white-text rounded-full bg-blue-500 border-2 border-white break-words hover:bg-blue-600">Configure Dependent Permissions</button> -->
                    <FieldRolePanel v-if="ISEDITPERMISSIONS" :field="element" @PermissionChange="PermissionChange" :inCheck="inCheck"
                        :allReportsRoles="allReportsRoles" @change="change" @changeCaseCreation="changeCaseCreation(element)" :class="{permissionRoleCheck:sectionItem, packageFieldRole:inPackage}" />
                </div>
                <div class="flex items-center justify-center">
                    <font-awesome-icon v-if="this.$route.params.action !== 'view' && showDeleteIcon && ISEDITPERMISSIONS && !element?.ristrictDrag"
                        class="cursor-pointer text-red-500 hover:text-red-700"
                        @click.stop="$emit('handleDeleteField', { element, section })" icon="trash"
                        v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
                </div>
            </div>

        </div>
        <div v-if="isLoading"  class="flex justify-center items-center gap-2 w-full">
        <Loader class="my-5" size="8"/>Loading
        </div>
        <div class="ml-4 mt-4">

            <RecursiveList :section="section" v-if="expanded" :queryData="queryData" :fields="nestedFields"
                :showSidePanel="showSidePanel" @saveBlockFields="$emit('saveBlockFields', $event)"
                @showfieldAddModal="$emit('showfieldAddModal', $event)"
                @handleUpdateBlock="$emit('handleUpdateBlock', $event)"
                @handleCollapseBlock="$emit('handleCollapseBlock', $event)"
                @handleFieldReorder="$emit('handleFieldReorder', $event, $event.element)"
                @handleRemoveBlock="$emit('handleRemoveBlock', $event)"
                @updateFieldLabel="$emit('updateFieldLabel', $event)"
                @handleDeleteField="$emit('handleDeleteField', $event)" @fieldNameClick="$emit('fieldNameClick', $event)"
                @PermissionChange="$emit('PermissionChange', $event)"
                @changeCaseCreation="$emit('changeCaseCreation', $event)"
                @changeblockVisibile="$emit('changeblockVisibile', $event)"
                @handleRemoveSection="$emit('handleRemoveSection', $event)"
                @showFieldSettingsModal="$emit('showFieldSettingsModal', $event)"
                @showGapValidationModal="$emit('showGapValidationModal', $event)"
                :updateFieldAttributesMethod="updateFieldAttributesMethod" :allReportsRoles="allReportsRoles"
                :dragStop="true" 
                :changeMethod="changeMethod"
                :changeCaseCreationMethod="changeCaseCreationMethod"
                />
        </div>
        <!-- <RecursiveList :section="section" v-if="expanded" :queryData="queryData" :fields="nestedFields" :showSidePanel="showSidePanel" /> -->

    </div>
</template>

<script>
import FieldRolePanel from "./field-roles-panel";
import FieldTag from "./FieldTag";
// import RecursiveList from "./RecursiveList.vue";
import Loader from "@/components/loader";
import axios from "@/axios";
// const CascadedIcon = () => import("@shared/assets/icons/field-cascaded.svg");
const GapInfo = () => import("./GapInfo.vue");
import Checkbox from "@/components/checkbox-alt";
export default {
    name: "fields",
    props: {
        queryData: Object,
        showSidePanel: {
            type: Boolean,
            default: false,
        },
        activeFieldPanel: {
            type: Object,
            default: () => { },
        },
        element: {
            type: Object,
            default: () => { },
        },
        section: {
            type: Object,
            default: () => { },
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: '',
        },
        showDeleteIcon: {
            type: Boolean,
            default: true
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        draggable: {
            type: Boolean,
            default: true
        },
        ISEDITPERMISSIONS: {
            type: Boolean,
            default: true
        },
        inPackage:{
            type: Boolean,
        },
        inCheck:{
            type: Boolean,
        },
        outSideBlockVisibility:{
            type: Boolean,
            default:true
        },
        changeMethod:{
            type:Function,
        },
        changeCaseCreationMethod:{
            type:Function
        },
        sectionItem:{
            type:Boolean,
            default:false
        },
        inFormAdmin:{
            type:Boolean,
            default:false
        }
    },
    components: {
        FieldRolePanel,
        // RecursiveList,
        RecursiveList: () => import('./RecursiveList.vue'),
        // CascadedIcon,
        GapInfo,
        FieldTag,
        Checkbox,
        Loader
    },
    data() {
        return {
            requiredId: null,
            edited: false,
            nestedFields: [],
            isExpanded: false,
            cascadeOpen:false,
            isLoading:false
        }
    },
    computed: {
        expanded() {
            return this.isExpanded
        },
        getElementName() {
            let text = ''
            const max_length = 20;
            if (this.element.name && this.element.name.length > max_length) {
                text = this.element.name.substring(0, max_length) + ' ...'
                return text
            }else{
                return this.element.name
            }
            
        },
        fieldLabel() {
            return this.getFieldLabel(this.element);
        },
        useblockvisible: {
            get() {
                return this.element.block_visibility
            },
            set(val) {
                this.element.block_visibility = val;
            },
        }
    },
    methods: {
        getTitle(element) {
            if (element.ui_label)
                return `Field Type:` + element.ui_label;
            else if (element.field_type)
                return `Field Type:` + element.field_type;
        },
        updateFAttribute(event, role) {
            console.log('updateFAttribute', event, role)
            // this.$emit('updateFieldAttributesMethod', event,role)    
        },
        async getOptions(field_id) {
            this.requiredId = this.$route.params?.packageName
            let apiUrl = this.requiredId ? `/package-section/${this.requiredId}` : 'tenant-check'
            const result = await axios.get(`${apiUrl}/field/${field_id}/options`)
            return result;
        },
        async getDependents(field_id, option_value) {
            this.requiredId = this.$route.params?.packageName
            let apiUrl = this.requiredId ? `package-section` : 'tenant-check'
            const result = await axios.get(`/${apiUrl}/field/${field_id}/options/${option_value}/dependents`);
            return result;
        },
        async fChange(element, section) {
            this.cascadeOpen = !this.cascadeOpen;
            if (this.isExpanded) {
                this.isExpanded = false
                this.isLoading=false
                return
            }else{
                this.isLoading=true;
            }
            if (!element) {
                this.activeFieldSettings = {
                    field: null,
                    section: null,
                };
                return;
            }
            element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
            this.activeFieldSettings = {
                field: element,
                section: section,
                is_cascaded: element.is_cascaded,
                
            };
            let options = await this.getOptions(element.id)
            options = options.data
            let fields = []
            // let data;
            for (let option of options.field_options) {
                fields.push({optionHere:true,
                            optionLabel:option.option_label,
                            id:option.id,
                            entity_type:'field' })
                if(!option?.is_dependent) {
                    continue;
                }
                let data = await this.getDependents(element.id, option.option_value)
                fields.push(...data.data.dependents)
            }

            fields = fields.map(item => {
                item.roles = item.permissions
                item.entity_type = "field"
                item.label = item.field_label
                item.use_in_gap_validation = item.use_in_gap_validation || false
                item.ristrictDrag = true

                return item
            })
            this.nestedFields = fields
            this.isExpanded = true
            this.isLoading=false
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
            // this.change();
        },
        changeblockVisibile(element,flag=false) {
            this.$emit('changeblockVisibile', element,flag);
        },
        async change({ role, backup }, isRole) {
            try {
                // this.$emit("PermissionChange", role);
                this.updateFAttribute({ section: this.section, element: this.element, role })
                // this.$emit('updateFieldAttributesMethod', this.section, this.element)
                await this.updateFieldAttributesMethod(this.section, this.element);
            } catch (error) {
                if (isRole) {
                    let match = this.element.roles.find((rl) => role.role_id === rl.role_id)
                    Object.assign(match, backup);
                }
            }
        },
        getFieldLabel(field) {
            return ['Candidate Consent', 'Candidate Optional Consent'].includes(field.type) ?
                field.type.replace('Candidate', '') :
                field.label;
        },
        changeCaseCreation($event,flag=false) {
            this.$emit('changeCaseCreation', $event,flag);
        },
        onEdit() {
            if (this.$route.params.action == 'view') {
                return
            }
            if (!this.element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.edited = !this.edited;
        },
        onUpdateFieldLabel(section, element) {
            if (!element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.$emit('updateFieldLabel', { section, field: element })
        }
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;
}

.block_visible {
    position: relative;
    left: -33px;
    width: 23px;
}
// .cst-border-left{
//     border-left: 10px solid #0d69d5;
// }
.cascadeOpen{
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                display: flex;
                justify-content: center;
                background-color: #0d6ad5;
                left:21px
            }
            .cascadeBgColor{
                background-color: #a1cdfb;
            }
 .arrowUpward{
    transform: rotate(180deg);
  transform-origin: 50% 50%;
 }   
 .packageFieldRole{
   margin-left:1.8%;
}      
// .PairedButton{
//     margin-left:-3.4%;
// }
// .permissionRoleCheck{
//     margin-left:3.4%;
// }
.FieldInfo{
    width:600px;
    min-width:600px;
}
.cascadeMargin{
    margin-left:1.6%;
}
</style>


           
