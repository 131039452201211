<template>
    <div v-if="checkData" class="shadow-md relative rounded-2xl bg-card-bg flex gap-6 flex-col">
        <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
            Check Details
            <button v-allow="'check.delete'" v-if="this.$route.params.action !== 'view'" @click="deleteCheck(checkData)" :disabled="this.$route.params.action == 'view'" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm">
                <font-awesome-icon icon="trash" />
            </button>
        </div>
        <div class="flex flex-col gap-3 p-3">
            <div class="flex w-full gap-6 items-stretch">
                <div class="flex flex-col w-2/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> Check Name </span>
                        <Input :disabled="this.$route.params.action == 'view'" inputClass="bg-gray-100 border-0 rounded-xl" class="h-10 w-full text-base-content" placeholder="Field Name" v-model="checkData.name" />
                    </div>
                    <div class="flex flex-col gap-3">
                        <span class="text-base-content text-md font-bold"> SLA Period</span>
                        <div class="flex items-center gap-5">
                            <Input :disabled="this.$route.params.action == 'view'" inputClass="bg-gray-100 border-0 rounded-xl" @keypress="isNumber" type="number" class="h-10 w-full text-base-content" placeholder="Field Name" v-model="checkData.sla" />
                            <span class="text-base-content text-md font-semibold w-1/4">Days</span>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> Check Category </span>
                        <span class="w-full text-base-content">{{ checkData.category }}</span>
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> Check Type </span>
                        <span class="w-full text-base-content">{{ checkData.subtype_name }}</span>
                    </div>
                </div>
                <div class="flex flex-col gap-3 w-3/5">
                    <span class="text-base-content text-md font-bold"> Check Description </span>
                    <textarea :disabled="this.$route.params.action == 'view'" v-model="checkData.description" class="rounded-xl bg-gray-100 max-w-full textarea textarea-bordered focus:textarea-primary overflow-hidden p-3 text-base-content flex-grow border-0" placeholder="Description" id="" cols="20"></textarea>
                </div>
            </div>
            <div v-if="checkData && checkData.parameters && checkData.parameters.parameters">
                <span class="text-base-content text-md font-bold"> Check Parameters </span>
                <div class="mt-2">
                    <div class="rounded flex gap-2 items-center" v-for="params in checkData.parameters.parameters" :key="params.name">
                        <div class="bg-indigo-200 py-1 px-2 text-base-content rounded">
                            {{ params.name }}
                        </div>
                        <input min="0" :type="params.value_type" @keypress="isNumber" class="text-center border border-b border-gray-200 h-8 w-12 rounded flex items-center justify-center" v-model="params.value" />
                    </div>
                </div>
            </div>
            <div class="mt-2 flex justify-end max-w-full gap-4" v-allow="'check.edit'" v-if="this.$route.params.action !== 'view'">
                <Button @click="saveCheck()" class="btn-primary float-right mt-4" text="Save" :disabled="this.$route.params.action == 'view'"/>
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/components/input";
import Button from "@/components/button";
import { isNumber } from "@/plugins/functions";

export default {
    name: "check-details",
    components: {
        Input,
        Button,
    },
    props: {
        checkData: {
            type: Object,
            default: null,
        },
    },
    methods: {
        isNumber,
        saveCheck() {
            this.$emit("handleSaveCheck");
        },
        deleteCheck(checkDetails) {
            this.$emit("handleDeleteCheck", checkDetails);
        },
    },
};
</script>

<style></style>
